body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Georgia;
  height: 100vh;
  background:#231F20;
  color: white;
}

label {
  display: block;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
}

input {
  height: 3rem;
  font-size: 1.5rem;
}
button {
  background: none;
  color: white;
  border: none;
  font-size: 2rem;
  border: 3px solid white;
  width: 3rem; 
  height: 3rem;
  border-radius: 50%;
  vertical-align: -3px;
  margin-left: 5px;
}

button:hover, button:focus {
  background: white;
  color:#231F20;
}